export default {
  button: {
    search: 'Search',
    post: 'Post',
  },
  heading: {
    post: 'Post',
    search: 'Search',
    localCarRentals: 'Local Car Rentals',
    inDominicanReplublic: 'In Dominican Republic',
    welcomeToCibaoRental: 'Welcome To Cibao Rental',
    whatAreYouWaitingFor: 'What are you waiting for?',
  },
  text: {
    homeWelcomeText: 'Save time money buy renting cars from local vehicle owners who are willing to give you excelent service.',
  },
  label: {},
};
