import Vue from 'vue';
import Router from 'vue-router';
import firebase from 'firebase';
import Home from './views/Home.vue';
import AuthenicationRoutes from './modules/Authentication/router';
import ClientRoutes from './modules/Client/router';
import BusinessRoues from './modules/Business/router';


Vue.use(Router);

let mainRoutes = [];
mainRoutes = mainRoutes.concat(ClientRoutes);
mainRoutes = mainRoutes.concat(BusinessRoues);
mainRoutes = mainRoutes.concat(AuthenicationRoutes);
mainRoutes = mainRoutes.concat([
  { path: '*', redirect: '/' },
  {
    path: '/',
    name: 'home',
    component: Home,
  },
]);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: mainRoutes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const { currentUser } = firebase.auth();

  if (requiresAuth && !currentUser) {
    next('/login');
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});
export default router;
