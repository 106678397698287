import Index from './index.vue'

export default [
  {
    path: '/client',
    name: 'client',
    component: Index,
    title: 'Client',
    children: [
    ],
  },
]
