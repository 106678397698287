import Index from './index.vue'

export default [
  {
    path: '/business',
    name: 'business',
    component: Index,
    title: 'Business',
    children: [
    ],
  },
]
