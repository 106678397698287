export default {
  button: {
    search: 'Buscar',
    post: 'Publicar',
  },
  heading: {
    post: 'Publicar',
    search: 'Buscar',
    localCarRentals: 'Alquilar Carros locales',
    inDominicanReplublic: 'en la Repulica Dominicana',
    welcomeToCibaoRental: 'Bienvenidos a Cibao Rental',
    whatAreYouWaitingFor: 'Que Esperas?',
  },
  text: {
    homeWelcomeText: 'Ahora tiempo y dinero con carros que estan cerca',
  },
  label: {},
};
