// eslint-disable-next-line
import english from './english';
import spanish from './spanish';

const messages = {
  en: english,
  es: spanish,
};

export default messages;
