<template>
 <div>
   <v-toolbar app dense scroll-off-screen>
    <v-btn flat icon to="/">
      <v-img max-height="40px" aspect-ratio="1" contain :src="require('../assets/logo.png')"></v-img>
    </v-btn>
      <v-toolbar-title class="headline text-uppercase">
        <span>Cibao Rental </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <LocaleChanger></LocaleChanger>
      </v-toolbar-items>
      
      <v-btn
        flat
        to="/login"
      >
        <span class="mr-2">Login</span>
      </v-btn>
    </v-toolbar>
    <v-img min-height="100vh" src="https://d2p1cf6997m1ir.cloudfront.net/media/places/countries/08/4e/26/a9e7/dominican-republic.jpg">
      <v-layout align-center justify-center column fill-height class="text-xs-center">
        <h1 class="white--text">{{ $t('heading.localCarRentals') }}</h1>
        <h3 class="white--text">{{ $t('heading.inDominicanReplublic') }}</h3>
        <SelectApp />
      </v-layout>
      
    </v-img>
   
  <HelloWorld />
  <v-img  position="center left" min-height="100vh" src="https://firebasestorage.googleapis.com/v0/b/cibaorental.appspot.com/o/asphalt-automobile-beach-1118448.jpg?alt=media&token=093e6f2b-b3ac-4866-ace6-bc30e488e5f3">
  <v-container class="text-xs-center">
    <h2 style="font-size:8vw">{{ $t('heading.whatAreYouWaitingFor') }}</h2>
    <v-layout align-center justify-center column fill-height>
      <SelectApp></SelectApp>
    </v-layout>
  </v-container>
  </v-img>
  <Footer />
 </div>
</template>

<script>
  import HelloWorld from '../components/HelloWorld'
  import SelectApp from '../components/SelectApp'
  import Footer from '../components/Footer.vue'
  import LocaleChanger from '../components/LocaleChanger.vue'
  export default {
    components: {
      HelloWorld,
      SelectApp,
      LocaleChanger,
      Footer
    },
    metaInfo: {
      title: 'Cibao Rental | Local Car Rental in Dominican Replubic',
      // override the parent template and just use the above title only
      titleTemplate: null,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Cibao Rental is the go to app for renting local calls from local rental car dealer ships or private car owners' }
      ]
    }
  }
</script>
