<template>
  <div>
    <v-toolbar app dense>
       <v-btn flat icon to="/">
        <v-img max-height="40px" aspect-ratio="1" contain :src="require('@/assets/logo.png')"></v-img>
      </v-btn>
      <v-toolbar-title class="headline text-uppercase">
        <span>{{ $t('heading.search') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        flat
        to="/"
      >
        <span class="mr-2">Home</span>
      </v-btn>
    </v-toolbar>

    <v-toolbar
      dense
      floating
      app
      style="margin: 50px auto"
    >
      <v-text-field
        hide-details
        prepend-icon="search"
        single-line
      ></v-text-field>

      <v-btn icon @click="locate">
        <v-icon>my_location</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>more_vert</v-icon>
      </v-btn>
    </v-toolbar>
    <GmapMap
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="position: fixed ;width: 100%; height: calc(100vh - 48px);"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      }"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center=m.position"
      />
    </GmapMap>
  </div>
</template>
<script>
export default {
  created() {
    // this.locate()
  },
  methods: {
    locate() {
      return this.$getLocation()
      .then(coordinates => {
        console.log(coordinates);
        this.center = coordinates
        this.zoom = 19
      });
    },
  },
  metaInfo: {
      title: 'Search for Cars | Cibao Rental',
      // override the parent template and just use the above title only
      titleTemplate: null
    },
    data() {
      return {
        center: {lat:19.019268, lng:-70.415338} ,
        zoom: 9,
        markers: [
          {
            position: {lat: -25.344, lng: 131.036}
          }
        ]
      }
    }
}
</script>

