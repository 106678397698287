import Login from './Login.vue';
import SignUp from './SignUp.vue';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    title: 'Login',
  },
  {
    path: '/signup',
    name: 'signUp',
    component: SignUp,
    title: 'Sign Up',
  },
];
