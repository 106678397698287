<template>
 <v-footer
    dark
    height="auto"
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-title class="teal" >
        <strong class="subheading">Website is in construction. Use at your own risk.</strong>
        <!-- <strong class="subheading">Get connected with us on social networks!</strong> -->

        <v-spacer></v-spacer>

        <!-- <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-3"
          dark
          icon
        >
          <v-icon size="24px">{{ icon }}</v-icon>
        </v-btn> -->
      </v-card-title>

      <v-card-actions class="grey darken-3 justify-center">
        &copy;2019 — <strong>Ariel German</strong> <LocaleChanger></LocaleChanger>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>
<script>
import LocaleChanger from './LocaleChanger'
  export default {
    components: {
      LocaleChanger,
    },
    data: () => ({
      icons: [
        'fab fa-facebook',
        'fab fa-twitter',
        'fab fa-google-plus',
        'fab fa-linkedin',
        'fab fa-instagram'
      ]
    })
  }
</script>
