<template>
  <div>
    <v-toolbar app dense>
      <v-btn flat icon to="/">
        <v-img max-height="40px" aspect-ratio="1" contain :src="require('@/assets/logo.png')"></v-img>
      </v-btn>
      <v-toolbar-title class="headline text-uppercase">
        <span>{{ $t('heading.post') }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        flat
        to="/"
      >
        <span class="mr-2">Home</span>
      </v-btn>
    </v-toolbar>
  </div>
</template>
<script>
export default {
  metaInfo: {
      title: 'Post Car for Rent | Cibao Rental ',
      // override the parent template and just use the above title only
      titleTemplate: null
    }
}
</script>
