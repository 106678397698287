<template>
    <v-layout
      text-xs-center
      column
    >
      <!-- <v-flex xs12>
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        ></v-img>
      </v-flex> -->

      <v-layout row class="my-4">
        <v-flex mb-4>
          <h2 class="display-2 font-weight-bold mb-3">
          {{ $t('heading.welcomeToCibaoRental') }}
          </h2>
          <p class="subheading font-weight-regular">
            {{ $t('text.homeWelcomeText') }}
          </p>
        </v-flex>
      </v-layout>

      <v-layout row wrap align-center justify-center fill-height class="text-xs-center"> 
        <v-flex xs12 sm6 order-xs2 order-sm1>
          <h4 style="font-size:7vh">Rent your own car</h4>
        </v-flex>
        <v-flex xs12 sm6 order-xs1 order-sm2>
          <v-img src="https://firebasestorage.googleapis.com/v0/b/cibaorental.appspot.com/o/blur-car-key-close-up-6097.jpg?alt=media&token=85447543-7bae-4f41-a83e-22637dc1b6a7">
          </v-img>
        </v-flex>
      </v-layout>
    <span><p style="font-size:5vw">OR</p>                                                                      </span>
      <v-layout row wrap align-center justify-center fill-height class="text-xs-center">
        <v-flex xs12 sm6 order-xs2 order-sm1>
          <v-img src="https://firebasestorage.googleapis.com/v0/b/cibaorental.appspot.com/o/blond-car-colors-720815.jpg?alt=media&token=99fffa8d-2e5c-4f90-908e-7db88767010f">
          </v-img>
        </v-flex>
        <v-flex xs12 sm6 order-xs1 order-sm2>
          <h4 style="font-size:7vh">Find the car that fits you</h4>
        </v-flex>
      </v-layout>

      <v-layout row v-if="false">
        <v-flex
          mb-5
          xs12
        >
          <h2 class="headline font-weight-bold mb-3">What's next?</h2>

          <v-layout justify-center>
            <a
              v-for="(next, i) in whatsNext"
              :key="i"
              :href="next.href"
              class="subheading mx-3"
              target="_blank"
            >
              {{ next.text }}
            </a>
          </v-layout>
        </v-flex>
      </v-layout>

    </v-layout>
</template>

<script>
  export default {
    data: () => ({
      whatsNext: [
        {
          text: 'Find Food',
          href: 'https://google.com'
        },
        {
          text: 'Find Friends',
          href: 'https://google.com'
        },

      ]
    })
  }
</script>

<style>

</style>
