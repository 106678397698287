import firebase from 'firebase';
import 'firebase/firestore';

// firebase init goes here
const config = {
  apiKey: 'AIzaSyDDID1hkZalMEURicoD1xtxtGxp-IaMXQQ',
  authDomain: 'cibaorental.firebaseapp.com',
  databaseURL: 'https://cibaorental.firebaseio.com',
  projectId: 'cibaorental',
  storageBucket: 'cibaorental.appspot.com',
  messagingSenderId: '142838859494',
  appId: '1:142838859494:web:b0103df3b07dbfff',
};
firebase.initializeApp(config);

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const { currentUser } = auth;

// firebase collections
const usersCollection = db.collection('users');
const postsCollection = db.collection('posts');
const commentsCollection = db.collection('comments');
const likesCollection = db.collection('likes');

export {
  db,
  auth,
  currentUser,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection,
};
