import Vue from 'vue';
import './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './plugins/vuei18n';
import meta from './plugins/vue-meta';
import maps from './plugins/vue2-googlemaps';
import './registerServiceWorker';

const fb = require('./firebaseConfig.js');

Vue.config.productionTip = false;

// handle page reloads
let app;
fb.auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      i18n,
      store,
      render: h => h(App),
      mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
    }).$mount('#app');
  }
});
